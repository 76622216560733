import * as React from "react";
import * as styles from "./sign-to-newsletter.module.css";

export default function SignToNewsLetter({
  firstname,
  email,
  submit,
  className = "",
}) {
  return (
    <div className={[styles.root, className].join(" ")}>
      <a className="sign-up-button button" href="http://eepurl.com/ieMGyH">Zapisz się!</a>
    </div>
  );
}
