import * as React from "react";
import * as styles from "./blog-posts.module.css";
import Section from "../components/section";
import BlogPostListItem from "../components/blog-post-list-item";
import Heading from "../components/heading";

export default function BlogPosts({heading, blog}) {
  return (
    <Section>
      <div className={styles.root}>
        <div className={styles.content}>
          <Heading as="h2" className={styles.heading}>
            {heading}
          </Heading>
          {blog.map(b => <BlogPostListItem asLink={true} {...b}/>)}
        </div>
      </div>
    </Section>
  );
}