import * as React from "react";
import * as styles from "./blog-post-list-item.module.css";
import { Link } from "gatsby";
import 'moment/locale/pl'
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function BlogPostListItem(blogPost) {
    var body = <>
        {blogPost.mainPhoto && (
            <GatsbyImage
                image={getImage(blogPost.mainPhoto)}
                alt={blogPost.mainPhoto.title}
                className={styles.image}
            />
        )}
        <div className={styles.heading}><span>{blogPost.title}</span></div>
    </>;

    if (blogPost.asLink) {
        body = <Link className={styles.link} to={`/${blogPost.slug}`}>{body}</Link>;
    }

    return <div className={styles.blogPost}>{body}</div>;
}