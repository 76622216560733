// extracted by mini-css-extract-plugin
export var content = "features-module--content--0bEmk";
export var contentContainer = "features-module--contentContainer--ktJar";
export var copyColumn = "features-module--copyColumn--stA-g";
export var copyContainer = "features-module--copyContainer--1eEoC";
export var featureContainer = "features-module--featureContainer--RK3YM";
export var featuresModule = "features-module--featuresModule--3EzK7";
export var imageColumn = "features-module--imageColumn--0LFbj";
export var primaryText = "features-module--primaryText--meLzA";
export var reverse = "features-module--reverse--TA-fw";
export var secondaryText = "features-module--secondaryText--oVOmy";