import React from "react";
import * as styles from "./copy.module.css";
import MarkdownText, { getText } from "../components/markdown-text";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Section from "../components/section";
import Heading from "../components/heading";

export default function Copy({ heading, secondaryHeading, content }) {
    return (
        <Section>
            <Heading center>{heading}</Heading>
            <Heading secondary center>
                {secondaryHeading}
            </Heading>
            <div className={styles.container}>
                {content.map((item) => {
                    return <CopyContent key={item.id} {...item} />;
                })}
            </div>
        </Section>
    );
}

function CopyContent({ primaryText, secondaryText, image }) {
    return (
        <div className={styles.copyContainer}>
            <MarkdownText {...primaryText}>
                <GatsbyImage
                    image={getImage(image)}
                    alt={image.title || getText(primaryText)}
                />
            </MarkdownText>
            <GatsbyImage
                    image={getImage(image)}
                    alt={image.title || getText(primaryText)}
                />
            <AsideText {...secondaryText} />
        </div>
    );
}

function AsideText(props) {
    return <MarkdownText className={styles.asideText} {...props} />;
}
